<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.admin_collaborators') }}</h1>

          <v-btn type="button" class="btn teal accent-4 text-white" @click="openEditDialog('invite', null)">
            <i class="fa fa-user"></i>
            <span>{{ $t('message.invite_collaborator') }}r</span>
          </v-btn>
      </header>

      <div class="clear"></div>

      <div v-if="!userClearances.length" class="subtitle-bread text-center mt-15">
        {{ $t('message.no_collaborators') }}
      </div>

      <div v-else>
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-text-field
              v-model="userFilter"
              :label="$t('message.search')"
            ></v-text-field>
          </v-col>
        </v-row>

        <div class="mb-4">
          <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2"
                 v-if="userClearances.length"
                 id="usersList">
            <thead>
            <tr>
              <th>{{ $t('message.collaborator') }}</th>
              <th
                v-for="(section, sectionIndex) in sections"
                :key="sectionIndex"
              >
                {{ $t('message.section_' + section.sectionName) }}
              </th>
            </tr>
            </thead>
            <tbody v-if="getOwner.length">
            <tr class="p-2 text-center d-block font-weight-bold subtitle-1">{{ $t('message.owner') }}</tr>
            <tr
              v-for="(userClearance, userIndex) in getOwner"
              :key="'manager-'+userIndex"
            >
              <td data-label="Nom :">
                <div class="controls float-left px-2">
                  <button class="btn btn-sm btn-danger mb-5 mr-5 white--text font-weight-bold invisible">
                    <i class="fas fa-trash"></i>{{ $t('message.delete') }}
                  </button>
                  <v-btn disabled text icon color="blue" class="d-block">
                  </v-btn>
                </div>
                <p class="collaborator float-left">
                  <span class="font-weight-bold">{{ userClearance.firstName }} {{ userClearance.lastName }}</span>
                  <br>
                  <span class="small">{{ $t('message.email') }} : {{ userClearance.email ? userClearance.email : '-' }}</span><br>
                  <span class="small">{{ $t('message.expires_on') }} : {{ userClearance.expirationDate ? userClearance.expirationDate : $t('message.no_date') }}</span><br>
                  <span class="small">{{ $t('message.last_connection') }} : {{ userClearance.lastLoginAt ? userClearance.lastLoginAt : '-' }}</span>
                </p>
              </td>
              <td
                v-for="(section, sectionIndex) in userClearance.sections"
                :key="sectionIndex"
              >
                <v-switch class="pl-4" disabled v-model="section.hasClearance"></v-switch>
              </td>
            </tr>
            </tbody>
            <tbody v-if="getManagers.length">
              <tr class="p-2 text-center d-block font-weight-bold subtitle-1">{{ $t('message.administrators') }}</tr>
              <tr
                v-for="(userClearance, userIndex) in getManagers"
                :key="'manager-'+userIndex"
              >
                <td data-label="Nom :">
                  <div class="controls float-left px-2">
                    <button
                      v-if="hasVaultProfile('OWNER')"
                      class="btn btn-sm btn-primary mb-5 white--text font-weight-bold"
                      @click="openEditDialog('edit', userClearance)"
                    >
                      <i class="fa fa-pen"></i>{{ $t('message.edit') }}
                    </button>
                    <button v-else class="btn btn-sm btn-primary mb-5 white--text font-weight-bold invisible">
                      <i class="fa fa-pen"></i>{{ $t('message.edit') }}
                    </button>
                    <br>
                    <button
                      class="btn btn-sm btn-danger mb-5 mr-5 white--text font-weight-bold"
                      v-if="!userClearance.hasUserCas && ($store.getters.getUser.id !== userClearance.id) && hasVaultProfile('OWNER')"
                      @click="openEditDialog('delete', userClearance)"
                    >
                      <i class="fas fa-trash"></i>{{ $t('message.delete') }}
                    </button>
                    <button v-else class="btn btn-sm btn-danger mb-5 mr-5 white--text font-weight-bold invisible">
                      <i class="fas fa-trash"></i>{{ $t('message.delete') }}
                    </button>
                  </div>
                  <p class="collaborator float-left">
                    <span class="font-weight-bold">{{ userClearance.firstName }} {{ userClearance.lastName }}</span>
                    <br>
                    <span class="small">{{ $t('message.email') }} : {{ userClearance.email ? userClearance.email : '-' }}</span><br>
                    <span class="small">{{ $t('message.expires_on') }} : {{ userClearance.expirationDate ? userClearance.expirationDate : $t('message.no_date') }}</span><br>
                    <span class="small">{{ $t('message.last_connection') }} : {{ userClearance.lastLoginAt ? userClearance.lastLoginAt : '-' }}</span><br>
                    <span class="small">{{ $t('message.invited_by') }} : {{ userClearance.createdBy ? userClearance.createdBy : '-' }}</span>
                  </p>
                </td>
                <td
                  v-for="(section, sectionIndex) in userClearance.sections"
                  :key="sectionIndex"
                >
                  <v-switch
                    class="pl-4"
                    :disabled="!hasVaultProfile('OWNER')"
                    v-model="section.hasClearance"
                    v-on:change="handleUserClearance(userClearance, section)"
                  >
                  </v-switch>
                </td>
              </tr>
            </tbody>
            <tbody v-if="getNormalUsers.length">
                <tr class="p-2 text-center d-block font-weight-bold subtitle-1">{{ $t('message.users') }}</tr>
                <tr
                v-for="(userClearance, userIndex) in getNormalUsers"
                :key="'user-'+userIndex"
              >
                <td data-label="Nom :">
                  <div class="controls float-left px-2">
                    <button class="btn btn-sm btn-primary mb-5 white--text font-weight-bold" @click="openEditDialog('edit', userClearance)">
                      <i v-if="hasVaultProfile('OWNER')" class="fa fa-pen"></i>
                      <i v-else class="fa fa-history"></i>{{ $t('message.edit') }}
                    </button>
                    <br>
                    <button class="btn btn-sm btn-danger mb-5 mr-5 white--text font-weight-bold" @click="openEditDialog('delete', userClearance)">
                      <i class="fas fa-trash"></i>{{ $t('message.edit') }}
                    </button>
                  </div>
                  <p class="collaborator float-left">
                    <span class="font-weight-bold">{{ userClearance.firstName }} {{ userClearance.lastName }}</span>
                    <br>
                    <span class="small">{{ $t('message.email') }} : {{ userClearance.email ? userClearance.email : '-' }}</span><br>
                    <span class="small">{{ $t('message.expires_on') }} : {{ userClearance.expirationDate ? userClearance.expirationDate : $t('message.no_date') }}</span><br>
                    <span class="small">{{ $t('message.last_connection') }} {{ userClearance.lastLoginAt ? userClearance.lastLoginAt : '-' }}</span><br>
                    <span class="small">{{ $t('message.invited_by') }} : {{ userClearance.createdBy ? userClearance.createdBy : '-' }}</span>
                  </p>
                </td>
                <td
                  v-for="(section, sectionIndex) in userClearance.sections"
                  :key="sectionIndex"
                >
                  <v-switch
                    class="pl-4"
                    :disabled="!hasVaultProfile('OWNER') && adminNotHasClearance(section)"
                    v-model="section.hasClearance"
                    v-on:change="handleUserClearance(userClearance, section)"
                  >
                  </v-switch>
                </td>
              </tr>
            </tbody>
            <tbody v-if="getFilteredExpiredUser.length">
              <tr class="p-2 text-center d-block font-weight-bold subtitle-1">{{ $t('message.expired') }}</tr>
              <tr
                v-for="(userClearance, userIndex) in getFilteredExpiredUser"
                :key="'expired-'+userIndex"
              >
                <td data-label="Nom :">
                  <div class="controls float-left px-2">
                    <button class="btn btn-sm btn-primary mb-5 white--text font-weight-bold" @click="openEditDialog('edit', userClearance)">
                      <i v-if="hasVaultProfile('OWNER')" class="fa fa-pen"></i>
                      <i v-else class="fa fa-history"></i>{{ $t('message.edit') }}
                    </button>
                    <br>
                    <button class="btn btn-sm btn-danger mb-5 mr-5 white--text font-weight-bold invisible" @click="openEditDialog('delete', userClearance)">
                      <i class="fas fa-trash"></i>{{ $t('message.delete') }}
                    </button>
                  </div>
                  <p class="collaborator float-left">
                    <span class="font-weight-bold">{{ userClearance.firstName }} {{ userClearance.lastName }}</span>
                    <br>
                    <span class="small">{{ $t('message.email') }} : {{ userClearance.email ? userClearance.email : '-' }}</span><br>
                    <span class="small">{{ $t('message.expires_on') }} : {{ userClearance.expirationDate ? userClearance.expirationDate : $t('message.no_date') }}</span><br>
                    <span class="small">{{ $t('message.last_connection') }} {{ userClearance.lastLoginAt ? userClearance.lastLoginAt : '-' }}</span><br>
                    <span class="small">{{ $t('message.invited_by') }} : {{ userClearance.createdBy ? userClearance.createdBy : '-' }}</span>
                  </p>
                </td>
                <td
                  v-for="(section, sectionIndex) in userClearance.sections"
                  :key="sectionIndex"
                >
                  <v-switch
                    class="pl-4"
                    disabled
                    v-model="section.hasClearance"
                    v-on:change="handleUserClearance(userClearance, section)"
                  >
                  </v-switch>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="inviteUserDialogState"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span v-if="deleteAccess" class="headline">{{ $t('message.delete_access') }}</span>
            <span v-else-if="!disableEmail" class="headline">{{ $t('message.user_invitation') }}</span>
            <span v-else class="headline">{{ $t('message.modify_expiration_date') }}</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="inviteForm">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    :disabled="disableEmail"
                    :rules="emailRules"
                    :label="$t('message.email') + '*'"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="!deleteAccess" cols="12">
                  <date-picker :value="expirationFormattedDate " @input="expirationFormattedDate = $event" valueType="format" format="DD/MM/YYYY">
                    <template v-slot:input>
                      <v-text-field :label="$t('message.expiration_date_limitation')" :value="expirationFormattedDate " @input="expirationFormattedDate = $event"></v-text-field>
                    </template>
                  </date-picker>
                </v-col>
              </v-row>
              <v-row v-if="!deleteAccess && (!disableEmail || hasVaultProfile('OWNER'))">
                <v-col cols="12">
                  <v-select
                    :label="$t('message.user_type')"
                    item-text="text"
                    item-value="value"
                    v-model="profile"
                    required
                    :items="getProfiles"
                    :rules="[v => !!v || $t('message.type_required')]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="inviteUserDialogState = false" color="blue darken-1">
              {{ $t('message.cancel') }}
            </v-btn>

            <v-btn text color="blue darken-1" @click="sendDialogForm">{{ $t('message.validate') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'
import DatePicker from 'vue2-datepicker';

export default {
  name: 'RightsManagement',
  components: { DatePicker },
  data: () => ({
    inviteForm: false,
    loading: false,
    userClearances: [],
    sections: [],
    inviteUserDialogState: false,
    showDatePicker: false,
    email: null,
    userFilter: '',
    disableEmail: true,
    deleteAccess: false,
    currentClearance: null,
    expirationFormattedDate: null,
    profile: null,
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.getUserClearances()
    document.title = config.title + ' - ' + this.$t('message.collaborators_management')
  },
  computed: {
    emailRules () {
      return [
        v => !!v || this.$t('message.mandatory_email'),
        v => /.+@.+\..+/.test(v) || this.$t('message.mandatory_valid_email')
      ]
    },
    profiles () {
      return [
        this.$t('message.administrators'),
        this.$t('message.users')
      ]
    },
    currentCompany () {
      return this.$store.getters.getCurrentCompany
    },
    currentUser () {
      return this.$store.getters.getUser
    },
    currentVault () {
      return this.$store.getters.getCurrentVault
    },
    getFilteredUsers () {
      const temp = this
      return this.userClearances.filter(e => {
        return (!e.hasExpired) &&
          (temp.userFilter.length === 0 || (
            e.email.toLowerCase().includes(temp.userFilter.toLowerCase()) ||
            e.firstName.toLowerCase().includes(temp.userFilter.toLowerCase()) ||
            e.lastName.toLowerCase().includes(temp.userFilter.toLowerCase())
          ))
      })
    },
    getFilteredExpiredUser () {
      const temp = this
      return this.userClearances.filter(e => {
        return (e.hasExpired) &&
          (temp.userFilter.length === 0 || (
            e.email.toLowerCase().includes(temp.userFilter.toLowerCase()) ||
            e.firstName.toLowerCase().includes(temp.userFilter.toLowerCase()) ||
            e.lastName.toLowerCase().includes(temp.userFilter.toLowerCase())
          ))
      })
    },
    getOwner () {
      const temp = this
      return temp.getFilteredUsers.filter(e => {
        return e.vaultProfile && e.vaultProfile.includes('OWNER')
      })
    },
    getManagers () {
      const temp = this
      return temp.getFilteredUsers.filter(e => {
        return e.vaultProfile && e.vaultProfile.includes('ADMIN') && !e.vaultProfile.includes('OWNER')
      })
    },
    getNormalUsers () {
      const temp = this
      return temp.getFilteredUsers.filter(e => {
        return !(e.vaultProfile && e.vaultProfile.includes('ADMIN'))
      })
    },
    getCurrentUser () {
      const temp = this
      const user = temp.getFilteredUsers.filter(e => {
        return e.id && e.id === this.currentUser.id
      })
      return user[0]
    },
    getProfiles () {
      if (this.hasVaultProfile('OWNER')) {
        return ['Administrateurs', 'Utilisateurs']
      }
      return ['Utilisateurs']
    }
  },
  methods: {
    openEditDialog (type, clearance) {
      this.inviteUserDialogState = true
      this.expirationFormattedDate = clearance?.expirationDate || null
      this.deleteAccess = false
      this.disableEmail = true
      this.currentClearance = clearance
      this.email = clearance?.email || ''
      this.profile = null

      if (clearance) {
        this.profile = clearance?.vaultProfile.includes('ADMIN') ? 'Administrateurs' : 'Utilisateurs'
      }

      if (type === 'invite') {
        this.email = null
        this.disableEmail = false
      } else if (type === 'delete') {
        this.deleteAccess = true
      }
    },
    sendDialogForm () {
      let data = {
        userMail: this.email,
        vaultId: this.currentVault.id,
        sectionId: this.sections.length ? this.sections[0].sectionId : null,
        expirationDate: this.expirationFormattedDate,
        profile: this.profile
      }
      let options = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }
      let path = '/user-vault'

      if (this.disableEmail) {
        data = {
          expirationDate: this.expirationFormattedDate,
          deleteAccess: this.deleteAccess,
          profile: this.profile
        }
        options = {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        }
        path = '/user-vault/' + this.currentClearance.userVaultId
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + path, formData, options)
        .then(response => {
          if (response.status === 200) {
            // success notify
            this.getUserClearances()
          }
          this.inviteUserDialogState = false
          this.email = null
          this.expirationFormattedDate = null
          return response
        })
        .catch(e => {
          const response = e.response

          if (response.status === 500) {
            this.$store.dispatch('messages/addMessage', { text: this.$t('message.error') })
          }

          this.loading = false
          return e.response
        })
        .then((event) => {
          if (event.data.notify) {
            JSON.parse(event.data.notify).forEach(e => {
              this.$store.dispatch('messages/addMessage', { text: e.text, type: e.type })
            })
          }
        })
    },
    getUserClearances () {
      this.loading = true

      const params = {
        company: this.currentCompany.id,
        vaultId: this.currentVault.id
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/user-clearances', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const userClearances = response.data
            if (userClearances && userClearances.length) {
              this.userClearances = userClearances
              this.sections = userClearances[0].sections
            }
          }
          this.loading = false
          return response
        })
        .catch(e => {
          const response = e.response

          if (response.status === 500) {
            this.$store.dispatch('messages/addMessage', { text: this.$t('message.error') })
          }

          this.loading = false
          return e.response
        })
        .then((event) => {
          if (event.data.notify) {
            JSON.parse(event.data.notify).forEach(e => {
              this.$store.dispatch('messages/addMessage', { text: e.text, type: e.type })
            })
          }
        })
    },
    handleUserClearance (userClearance, section) {
      if (section.hasClearance) {
        this.postClearance(userClearance, section)
      } else {
        this.deleteClearance(userClearance, section)
      }
    },
    postClearance (userClearance, section) {
      const data = {
        userId: userClearance.id,
        vaultId: this.currentVault.id,
        sectionId: section.sectionId
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      const options = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/clearances', formData, options)
        .then(response => {
          if (response.status === 200) {
            this.getUserClearances()
            this.$store.dispatch('messages/addMessage', { text: this.$t('message.update_saved'), type: 'green' })
          }
          return response
        })
        .catch(e => {
          const response = e.response

          if (response.status === 500) {
            this.$store.dispatch('messages/addMessage', { text: this.$t('message.error') })
          }

          this.loading = false
          return e.response
        })
        .then((event) => {
          if (event.data.notify) {
            JSON.parse(event.data.notify).forEach(e => {
              this.$store.dispatch('messages/addMessage', { text: e.text, type: e.type })
            })
          }
        })
    },
    deleteClearance (userClearance, section) {
      this.loading = true

      Vue.prototype.$http
        .delete(config.apiUrl + '/clearances/' + section.clearanceId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          this.getUserClearances()
          return response
        })
        .catch(e => {
          const response = e.response

          if (response.status === 500) {
            this.$store.dispatch('messages/addMessage', { text: this.$t('message.error') })
          }

          this.loading = false
          return e.response
        })
        .then((event) => {
          if (event.data.notify) {
            JSON.parse(event.data.notify).forEach(e => {
              this.$store.dispatch('messages/addMessage', { text: e.text, type: e.type })
            })
          }
        })
    },
    hasRole (role, company) {
      return this.$store.getters.hasRole(role, company)
    },
    hasVaultProfile (profile) {
      if (this.currentVault) {
        const vaultsRole = this.currentVault.vaultsRole

        for (let i = 0; i < vaultsRole.length; i++) {
          if (vaultsRole[i].profiles.includes(profile)) {
            return true
          }
        }
      }

      return false
    },
    getCollaboratorUrl () {
      return config.collaboratorUrl
    },
    adminNotHasClearance (section) {
      const clearance = this.getCurrentUser.sections.find(item => item.sectionId === section.sectionId)

      return !clearance.hasClearance
    }
  }
}
</script>

<style scoped>
#usersList {
  border: none;
}
#usersList tbody tr:last-child td {
  border-bottom: none;
}
.mx-datepicker {
  width: 100%;
}
</style>
